<template>
  <div ref="mapChart" class="map-chart"></div>
</template>
<script>
import TJMap from "@/assets/json/tianjin_map.json";
export default {
  name: "MapChart",
  props: {
    mapData: {
      type: Array,
      default: () => []
    }
  },
  watch: {
    mapData: {
      deep: true,
      handler: function () {
        this.setMapData()
        this.chartDom.setOption(this.option, true)
      }
    }
  },

  data () {
    return {
      dataList: [
        { ename: "heping", name: "和平区", code: 120101 },
        { ename: "hedong", name: "河东区", code: 120102 },
        { ename: "hexi", name: "河西区", code: 120103 },
        { ename: "nakai", name: "南开区", code: 120104 },
        { ename: "hebei", name: "河北区", code: 120105 },
        { ename: "hongqiao", name: "红桥区", code: 120106 },
        { ename: "dongli", name: "东丽区", code: 120110 },
        { ename: "xiqing", name: "西青区", code: 120111 },
        { ename: "jinna", name: "津南区", code: 120112 },
        { ename: "beichen", name: "北辰区", code: 120113 },
        { ename: "wuqing", name: "武清区", code: 120114 },
        { ename: "baodi", name: "宝坻区", code: 120115 },
        { ename: "binhai", name: "滨海新区", code: 120116 },
        { ename: "dinghe", name: "宁河区", code: 120117 },
        { ename: "jinghai", name: "静海区", code: 120118 },
        { ename: "jizhou", name: "蓟州区", code: 120118 },
      ],
      chartDom: null,
      option: {
        tooltip: {
          trigger: 'item',
          formatter: function (params) {
            var toolTiphtml = `${params.data.name}<br>
                              项目数量<br>
                              <span style="display:inline-block;margin-right:5px;border-radius:50%;width:10px;height:10px;left:5px;background-color: #00C6FF"></span>
                              ${params.data.title} : ${params.data.value} <br>
                              <span style="display:inline-block;margin-right:5px;border-radius:50%;width:10px;height:10px;left:5px;background-color: #34D160"></span>
                              ${params.data.title1} : ${params.data.value1} <br>
                              `
            return toolTiphtml;
          }
        },

        series: [
          {
            name: '天津',
            type: 'map',
            map: 'tianjin',
            selectedMode: 'single', //是否允许选中多个区域
            roam: true, //是否开启鼠标缩放和平移漫游
            top: '80px',
            label: {
              show: true, //显示省份标签
              color: '#fefefe', //省份标签字体颜色
            },
            itemStyle: {
              borderWidth: 2, //区域边框宽度
              borderColor: '#14A9FF', //区域边框颜色
              areaColor: '#2C3E89', //区域颜
            },
            // 鼠标悬浮效果
            emphasis: {
              label: {
                show: true,
                color: '#fefefe',
              },
              itemStyle: {
                borderWidth: 2,
                borderColor: '#14A9FF',
                areaColor: '#06AAFF',
              }
            },
            // 选中的区域边
            select: {
              label: {
                show: true,
                color: '#fefefe'
              },
              itemStyle: {
                borderWidth: 2,
                borderColor: '#14A9FF',
                areaColor: '#06AAFF',
              }
            },
            data: this.dataList,
          },
        ],
      }
    }
  },
  mounted () {
    this.setMapData()

    this.$nextTick(() => {
      this.initEcharts()
    })
  },
  methods: {
    setMapData () {
      for (let i = 0; i < this.dataList.length; i++) {
        this.dataList[i]['title'] = "专利"
        this.dataList[i]['title1'] = "商标"
        this.dataList[i]['value'] = 0
        this.dataList[i]['value1'] = 0
        if (this.mapData.length) {
          const dataObj = this.mapData.find(item => item.region_code == this.dataList[i].code) || {}
          this.dataList[i]['value'] = dataObj['patent_project_num']
          this.dataList[i]['value1'] = dataObj['trademark_project_num']
        }
      }
      this.option.series[0].data = this.dataList
    },
    // 地图回钻
    returnUpLevel (value, isResize) {
      this.dataList.forEach(item => {
        if (this.$store.getters.dataCondition.map.code === item.code) {
          item['selected'] = true
        } else {
          item['selected'] = false
        }
      })

      if (value.code === 120000) {
        this.option.series[0].map = 'tianjin'
        this.chartDom.setOption(this.option, true)
      }
      if (isResize) {
        this.$nextTick(() => {
          this.chartDom.resize();
        })
      }
    },

    initEcharts () {

      this.chartDom = this.$echarts.init(this.$refs.mapChart)

      this.$echarts.registerMap('tianjin', TJMap)


      this.chartDom.setOption(this.option, true)

      this.chartDom.on("click", (params) => {
        this.$emit('changeMap', { name: params.name, code: params.data.code })
      });
      //随着屏幕大小调节图表
      window.addEventListener("resize", () => {
        this.chartDom.resize();
      });
    },
  }
}
</script>

<style lang="scss" scoped>
.map-chart {
  width: 100%;
  height: 100%;
}
</style>