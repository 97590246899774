<template>
  <div class="data-s-home flex" ref="dataSHome">
    <!-- 左侧 -->
    <el-row
      :class="[
        'dsh-left',
        'flex-column',
        { 'data-con-figure-two': !isAllMap && activeNav !== 'pledge' },
      ]"
    >
      <el-col
        :class="[
          'data-box',
          'left-con-1',
          { 'flex-1': activeNav === 'pledge' },
        ]"
      >
        <div style="text-align: center">
          <div
            class="data-con-title"
            :style="{ marginBottom: activeNav === 'pledge' ? '20px' : '0px' }"
          >
            <el-dropdown trigger="click" @command="changeYear">
              <span class="el-dropdown-link">
                {{ activeYear }} 年<i
                  class="el-icon-caret-bottom el-icon--right"
                ></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  v-for="year in years"
                  :key="year"
                  :command="year"
                  :class="[{ active: activeYear == year }]"
                  >{{ year }}</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
            <div class="dct-info" style="text-align: right">
              <p style="margin-bottom: 5px">截止至：{{ deadline_date }}</p>
              <router-link
                v-if="!isAllMap && activeNav === 'pledge'"
                style="color: #409eff"
                to="/data_service/area"
                >查看详情 >></router-link
              >
            </div>
          </div>
          <router-link
            v-if="!isAllMap && activeNav !== 'pledge'"
            style="color: #409eff; font-size: 22px; margin-top: 50px"
            to="/data_service/area"
            >查看详情 >></router-link
          >
        </div>

        <el-row
          v-if="activeNav === 'pledge'"
          :class="['data-con-figure', { 'flex-column dcf-area': !isAllMap }]"
        >
          <el-col>
            <p class="dcf-title">{{ activeMapNav.name }}质押项目数量 (个)</p>
            <CountTo
              class="dcf-num"
              :startVal="0"
              :endVal="activeData.pledge_project_num || 0"
              separator=""
            />
          </el-col>
          <el-divider
            :direction="isAllMap ? 'vertical' : 'horizontal'"
          ></el-divider>
          <el-col>
            <p class="dcf-title">{{ activeMapNav.name }}质押项目件数 (件)</p>
            <CountTo
              class="dcf-num"
              :startVal="0"
              :endVal="activeData.pledge_num || 0"
              separator=""
            />
          </el-col>
        </el-row>
      </el-col>

      <el-col
        v-if="activeNav === 'patent' || activeNav === 'pledge'"
        :class="[
          'data-box',
          'left-con-2',
          { 'flex-1': activeNav === 'patent' },
        ]"
        :style="{ height: kHTwo + 'px' }"
      >
        <el-row
          :class="[
            'data-con-figure',
            { 'flex-column dcf-area': activeNav === 'patent' },
          ]"
        >
          <el-col>
            <p class="dcf-title">{{ activeMapNav.name }}专利项目数量 (个)</p>
            <CountTo
              class="dcf-num"
              :startVal="0"
              :endVal="activeData.patent_project_num || 0"
              separator=""
            />
          </el-col>
          <el-divider
            :direction="activeNav === 'patent' ? 'horizontal' : 'vertical'"
          ></el-divider>
          <el-col>
            <p class="dcf-title">{{ activeMapNav.name }}专利项目件数 (件)</p>
            <CountTo
              class="dcf-num"
              :startVal="0"
              :endVal="activeData.patent_num || 0"
              separator=""
            />
          </el-col>
        </el-row>
      </el-col>

      <el-col
        v-if="activeNav === 'trademark' || activeNav === 'pledge'"
        :class="[
          'data-box',
          'left-con-3',
          { 'flex-1': activeNav === 'trademark' },
        ]"
        :style="{ height: kHTwo + 'px' }"
      >
        <el-row
          :class="[
            'data-con-figure',
            { 'flex-column dcf-area': activeNav === 'trademark' },
          ]"
        >
          <el-col>
            <p class="dcf-title">{{ activeMapNav.name }}商标项目数量 (个)</p>
            <CountTo
              class="dcf-num"
              :startVal="0"
              :endVal="activeData.trademark_project_num || 0"
              separator=""
            />
          </el-col>
          <el-divider
            :direction="activeNav === 'trademark' ? 'horizontal' : 'vertical'"
          ></el-divider>
          <el-col>
            <p class="dcf-title">{{ activeMapNav.name }}商标项目件数 (件)</p>
            <CountTo
              class="dcf-num"
              :startVal="0"
              :endVal="activeData.trademark_num || 0"
              separator=""
            />
          </el-col>
        </el-row>
      </el-col>

      <el-col
        v-if="isAllMap"
        class="data-box left-con-4"
        :style="{ height: kHThree + 'px' }"
      >
        <div class="data-con-title">
          <p class="dct-label">{{ activeNavName }}金额</p>
          <p class="dct-info">比较：金额（亿元）</p>
        </div>
        <bar-chart
          :barData="areaAllData"
          :sortKey="`${activeNav}_amount`"
          @gotoDetails="gotoDetails"
        />
      </el-col>
    </el-row>

    <!-- 中间 -->
    <el-row class="dsh-cen flex-1 flex-column">
      <el-col class="cen-con-1">
        <el-row :gutter="20" type="flex" class="dsh-navs" justify="center">
          <el-col :span="6" v-for="(item, index) in navs" :key="index">
            <div
              :class="['data-nav', { active: activeNav == item.value }]"
              @click="changeNav(item.value)"
            >
              {{ item.name }}
            </div>
          </el-col>
        </el-row>
      </el-col>

      <el-col class="cen-con-2 mb-20">
        <p class="cen-title">
          {{ activeMapNav.name
          }}{{ activeNavName !== '质押' ? activeNavName : '知识产权' }}质押金额
        </p>
        <p class="cen-money">
          ￥<span> {{ totalPrice }} </span>亿元
        </p>
      </el-col>

      <el-col class="cen-con-3 flex-1">
        <ul class="dsh-map-navs flex">
          <li
            class="dsh-m-nav"
            v-for="item in mapNavs"
            :key="item.code"
            @click="changeMapNav(item)"
          >
            <span class="dsh-m-n_inner">{{ item.name }}</span>
            <i class="el-icon-arrow-right"></i>
          </li>
        </ul>
        <map-chart
          ref="mapChart"
          :mapData="areaAllData"
          @changeMap="changeMapNav"
        />
      </el-col>

      <el-col
        v-if="isAllMap"
        class="data-box cen-con-4"
        :style="{ height: kHThree + 'px' }"
      >
        <div class="data-con-title">
          <p class="dct-label">近五年趋势图展示</p>
          <p class="dct-info">年份 / 金额(亿元)</p>
        </div>
        <line-chart ref="LineChart" type="total" :lineData="fiveYearsData" />
      </el-col>
    </el-row>

    <!-- 右侧 -->
    <el-row class="dsh-right flex-column">
      <template v-if="isAllMap">
        <el-col class="data-box right-con-1 flex-1">
          <div class="data-con-title">
            <p class="dct-label">{{ activeNavName }}项目地区排行</p>
            <p class="dct-info">比较：项目数(件)</p>
          </div>
          <bar-chart
            :barData="areaAllData"
            :sortKey="`${activeNav}_project_num`"
            @gotoDetails="gotoDetails"
          />
        </el-col>

        <el-col
          class="data-box right-con-2"
          :style="{ height: kHThree + 'px' }"
        >
          <div class="data-con-title">
            <p class="dct-label">{{ activeNavName }}项目地区分布</p>
            <p class="dct-info">比较：项目数(件)</p>
          </div>
          <pie-chart
            :pieData="areaAllData"
            :sortKey="`${activeNav}_project_num`"
          />
        </el-col>
      </template>

      <template v-else>
        <AreaRight class="flex-column flex-1" :navType="activeNav" />
      </template>
    </el-row>
  </div>
</template>

<script>
import * as dataApi from "@/api/data_service.js";
import { mapGetters } from 'vuex'
import { numAdd } from "@/utils/index.js"
import CountTo from 'vue-count-to'
import BarChart from "./components/chart/bar.vue";
import LineChart from "./components/chart/line.vue";
import PieChart from "./components/chart/pie.vue";
import MapChart from "./components/chart/map.vue";
import AreaRight from "./components/area_right.vue";
export default {
  name: "DataHome",
  components: {
    CountTo,
    BarChart,
    LineChart,
    PieChart,
    MapChart,
    AreaRight
  },
  data () {
    return {
      // 导航
      navs: [
        { name: "专利", value: "patent" },
        { name: "总览", value: "pledge" },
        { name: "商标", value: "trademark" }
      ],
      activeNav: 'pledge',// 已选导航
      deadline_date: '',//截止时间
      years: [],// 可选年份
      activeYear: '',// 已选年份
      yearData: [],//所有年份数据
      areaAllData: [],//所有地区数据
      activeData: {},//选中的数据
      mapNavs: [{ name: '天津市', code: 120000 }],//地图面包屑
      fiveYearsData: [['product'], ['全国专利'], ['全国商标'], ['全国质押']],//五年折线图数据
      kHTwo: '300',
      kHThree: '300',
    };
  },
  computed: {
    isAllMap () {
      return this.mapNavs.length <= 1
    },
    activeNavName () {
      let nameStr = '质押'
      switch (this.activeNav) {
        case 'patent':
          nameStr = '专利'
          break;
        case 'pledge':
          nameStr = '质押'
          break;
        case 'trademark':
          nameStr = '商标'
          break;
        default:
          break;
      }
      return nameStr
    },
    totalPrice () {
      return this.activeData[`${this.activeNav}_amount`] || 0
    },
    activeMapNav () {
      let mapNav = this.mapNavs[this.mapNavs.length - 1]
      // 更新左侧数字数据
      if (mapNav.code === 120000) {
        this.activeData = this.yearData.find(i => i.year_date === this.activeYear) || {}
      } else {
        this.activeData = this.dataAreaDetails || {}
      }
      this.saveCondition('map', mapNav)
      return mapNav
    },
    ...mapGetters([
      'userInfo',
      'dataCondition',
      'dataAreaDetails'
    ])
  },

  async mounted () {
    this.getScreenHeight()
    await this.getYearData()
    await this.changeYear(this.activeYear)
    await this.changeNav(this.activeNav)
  },

  methods: {
    // 年份数据
    async getYearData () {
      try {
        let { data } = await dataApi.getYearData()
        this.deadline_date = data.screen_date
        this.yearData = data.data
        this.activeYear = data.data[0].year_date
        // 可选择的年份
        this.years = data.data.map(item => {
          return item.year_date
        });
        // 根据截至日期获取近五年数据
        const deadline_year = this.deadline_date.slice(0, 4) * 1
        for (let index = 5; index > 0; index--) {
          const year = deadline_year - index + 1
          this.fiveYearsData[0].push(year)
          const ind = this.yearData.findIndex(i => i.year_date === year)
          this.fiveYearsData[1].push(ind !== -1 ? this.yearData[ind].patent_amount : 0)
          this.fiveYearsData[2].push(ind !== -1 ? this.yearData[ind].trademark_amount : 0)
          this.fiveYearsData[3].push(ind !== -1 ? this.yearData[ind].pledge_amount : 0)
        }
      } catch (error) {
        return false
      }
    },
    // 地区数据
    async getAllAreaData () {
      try {
        let { data } = await dataApi.getAllAreaData(this.activeYear)
        this.areaAllData = data.data.map(item => {
          item['pledge_amount'] = numAdd(item['patent_amount'], item['trademark_amount'])
          item['pledge_num'] = item['patent_num'] + item['trademark_num']
          item['pledge_project_num'] = item['patent_project_num'] + item['trademark_project_num']
          return item
        })
      } catch (error) {
        return false
      }
    },
    saveCondition (key, value) {
      this.dataCondition[key] = value
      this.$store.dispatch('setDataCondition', this.dataCondition)
    },
    // 切换年份
    changeYear (value) {
      this.activeYear = value
      this.activeData = this.yearData.find(i => i.year_date === value) || {}
      this.saveCondition('year', value)
      this.getAllAreaData()
    },
    // 切换导航
    changeNav (value) {
      this.activeNav = value
      this.saveCondition('nav', value)
    },
    // 切换地图
    changeMapNav (value) {
      if (this.userInfo.region!=1 && value.code!=120000 && this.userInfo.region!=value.code) {
        return false
      }
      const isResize = this.activeMapNav.code === 120000
      let mapNavsInd = this.mapNavs.findIndex(i => i.code === value.code)
      if (mapNavsInd === -1) {
        this.mapNavs.splice(1, 1, value)
      } else {
        this.mapNavs.splice(1)
      }
      this.getScreenHeight()
      this.$refs.mapChart.returnUpLevel(this.activeMapNav, isResize || this.activeMapNav.code === 120000)
    },
    // 跳转详情页
    gotoDetails (sortKey) {
      // this.$router.push({
      //   name: 'DataDetails',
      //   query: {
      //     sortKey
      //   }
      // })
    },
    // 获取浏览器高度进行自适应
    getScreenHeight () {
      this.screenHeight = window.innerHeight || document.documentElement.innerHeight || document.body.clientHeight;
      this.screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      this.screenHeight -= this.$refs.dataSHome.getBoundingClientRect().top + 50
      let num = 0
      switch (true) {
        case this.screenWidth >= 1600:
          num = 0.263
          break;
        case this.screenWidth >= 1500:
          num = 0.273
          break;
        case this.screenWidth >= 1400:
          num = 0.278
          break;
        case this.screenWidth >= 1300:
          num = 0.283
          break;
        case this.screenWidth >= 1200:
          num = 0.29
          break;

        default:
          break;
      }
      // 四舍五入取整数
      this.kHTwo = Math.round(this.screenHeight * (this.isAllMap ? 0.16 : num));
      this.kHThree = Math.round(this.screenHeight * 0.33);
    },
  },
};
</script>

<style scoped lang="scss">
// 左侧
.dsh-left {
  .left-con-1 {
    min-height: 184px;
    .dcf-num {
      color: #00c6ff;
    }
  }
  .left-con-2 {
    .dcf-num {
      color: #f19610;
    }
  }
  .left-con-3 {
    .dcf-num {
      color: #34d160;
    }
  }
  .left-con-2,
  .left-con-3 {
    min-height: 125px;
  }
  .dcf-area {
    height: 100%;
    .el-col {
      height: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .el-divider {
      height: 1px !important;
    }
  }
  .el-dropdown-link {
    color: #fefefe;
    font-size: 26px;
    font-weight: bold;
  }
  .data-con-figure {
    display: flex;
    align-items: center;
    text-align: center;
    .dcf-title {
      font-size: 18px;
      margin-bottom: 20px;
    }
    .dcf-num {
      font-size: 48px;
      font-weight: bold;
    }
    .el-divider {
      height: 100%;
      background-color: #00c6ff;
    }
  }
  &.data-con-figure-two {
    .data-con-figure {
      .dcf-title {
        margin-bottom: 50px;
      }
    }
  }
}

// 中间
::v-deep .dsh-cen {
  margin: 0 10px;
  .data-nav {
    width: 100%;
    line-height: 50px;
    font-size: 18px;
    font-weight: bold;
    color: #0dcef1;
    letter-spacing: 12px;
    text-align: center;
    text-indent: 12px;
    background: url('../../assets/images/data_service/nav.png') center/100% 100%
      no-repeat;
    cursor: pointer;
    &.active {
      font-size: 26px;
      color: #f1b30d;
    }
  }

  .dsh-map-navs {
    position: absolute;
    z-index: 1;
    padding: 20px;
    color: #fefefe;
    font-weight: bold;
    .dsh-m-nav {
      .dsh-m-n_inner {
        cursor: pointer;
        transition: color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
        &:hover {
          color: #409eff;
        }
      }
      i {
        margin: 0 6px;
        font-weight: bold;
      }
      &:last-child i {
        display: none;
      }
    }
  }
  .cen-con-2 {
    text-align: center;
    font-weight: bold;
    .cen-title {
      margin: 20px 0 5px;
    }
    .cen-money {
      span {
        font-size: 32px;
      }
    }
  }
  .cen-con-4 {
    margin-top: 20px;
  }
}

.el-dropdown-menu li {
  &.active {
    color: #0dcef1;
  }
}
</style>
