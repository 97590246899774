<template>
  <div ref="pieChart" class="pie-chart"></div>
</template>
<script>
export default {
  name: "PieChart",
  props: {
    // 数据
    pieData: {
      type: Array,
      default: () => []
    },
    // 排序字段
    sortKey: {
      type: String,
      default: 'pledge'
    }
  },
  watch: {
    pieData: {
      deep: true,
      handler: function () {
        this.draw()
      }
    },
    sortKey: {
      deep: true,
      handler: function () {
        this.draw()
      }
    },
  },
  data () {
    return {
      chartDom: null
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.initEcharts()
    })
  },
  methods: {
    initEcharts () {

      this.chartDom = this.$echarts.init(this.$refs.pieChart);
      this.draw()
      //随着屏幕大小调节图表
      window.addEventListener("resize", () => {
        this.chartDom.resize();
      });
    },
    draw () {
      const dataVal = JSON.parse(JSON.stringify(this.pieData || []))
      const sortedData = dataVal.sort((a, b) => { return b[this.sortKey] - a[this.sortKey] });
      let newData = []
      for (let i = 0; i < sortedData.length; i++) {
        if (i <= 3) {
          newData.push({ value: sortedData[i][this.sortKey], name: sortedData[i].region_name })
        } else {
          if (newData[4]) {
            newData[4].value += sortedData[i][this.sortKey]
          } else {
            newData.push({ value: sortedData[i][this.sortKey], name: "其他" })
          }
        }
      }
      let option = {
        tooltip: {
          trigger: 'item',
        },
        color: ['#0278E7', '#8A00E1', '#34D160', '#F19610', '#00C6FF', '#6054FF ', '#fc8452', '#9a60b4', '#ea7ccc'],
        series: [
          {
            type: 'pie',
            top: "6px",
            radius: ['40%', '76%'],
            avoidLabelOverlap: false,
            startAngle: 180,//起始角度，支持范围[0, 360]。
            minAngle: 10, //最小的扇区角度（0 ~ 360），用于防止某个值过小导致扇区太小影响交互
            label: {
              formatter: '{b}\n{d}%',
              color: '#fefefe'
            },
            labelLine: {
              length: 15
            },
            data: newData,
          },
        ]
      }
      this.chartDom.setOption(option);

    }
  }
}
</script>

<style lang="scss" scoped>
.pie-chart {
  width: 100%;
  height: 100%;
}
</style>